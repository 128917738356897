import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query counsellingQuery {
      img0: file(relativePath: { eq: "counselling_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0cn: file(relativePath: { eq: "counselling_header_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0en: file(relativePath: { eq: "counselling_header_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "counselling_case.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.counselling" description="seo.desc.nsp" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
      <Container className="counselling-container">
        <Row className="counselling-row">
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="pb-3">
            <div className="counselling-content">
              <p>
                {tt("pages.counselling.desc1_1")}
                <Link to="/convoicedisorder">
                  {tt("pages.counselling.desc1_link1")}
                </Link>
                {tt("pages.counselling.desc1_2")}
                <Link to="/#Syndromes">
                  {tt("pages.counselling.desc1_link2")}
                </Link>
                {tt("pages.counselling.desc1_3")}
              </p>
              <p>
                <b className="title">{tt("pages.counselling.title2")}</b>
                {tt("pages.counselling.desc2")}
              </p>
              <p>
                <b className="title">{tt("pages.counselling.title3")}</b>
                {tt("pages.counselling.desc3")}
              </p>
              <p>{tt("pages.counselling.desc4")}</p>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
            <Img fluid={data.img1.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ThePage
